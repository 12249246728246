
.page-title {
    text-align: center;
    background-color: #3a3a3a;
    color: #52c8fa;
    font-size: large;
    font-family: "Bungee Regular";
    max-width: 800px;
    line-height: 150%;
    margin: 0 auto;
    padding-bottom: 0;
    border: 1px solid #ffffff;
    border-radius: 0px 0px 4px 4px;
}
.page-title {
    text-shadow: 1px 1px #3a3a3a, -1px -1px #eaeaea;
}