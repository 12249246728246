.article-source {
    color: var(--main-medium-gray);
}
.article-date {
    font-family: var(--item-title-font);
    color: var(--main-blue);
    display: inline-block;
    line-height: 100%;
}
.article-headline {
    color: var(--main-blue);
    font-size: 24px;
    margin: 0 auto;
}
.article-teaser {
    display: inline-block;
    padding-bottom: 5px;
}
.article-item-div {
    padding-top: 8px;
    padding-bottom: 8px;
}
.article-paragraph {
    display: inline-block;
    padding-bottom: 5px;
}
.article-image {
    display: block;
    max-height: 25vh;
    margin: auto;
    padding: 5px;
    border: 1px solid var(--main-medium-gray);
    border-radius: 2px;
    max-width: 80%;
}
.article-image-main {
    display: block;
    margin: auto;
    padding: 5px;
    border: 1px solid var(--main-medium-gray);
    border-radius: 2px;
    max-width: 95%;
}