.disclaimer-wrapper {
    padding: 10px;
    max-width: 800px;
    margin: 0 auto;
    text-align: justify;
}
.disclaimer-content {
    color: #7e7e7e;
    font-size: small;
}
.disclaimer-divider-line {
    width: 80%;
	border-top: 1px dotted #7e7e7e;
	border-bottom: 1px dotted #ffffff;
}
.creative-ape-notice {
    color: #7e7e7e;
    font-size: small;
    text-align: center;
}
.creative-ape-notice a {
    text-decoration: none;
    color: #3a3a3a;
}