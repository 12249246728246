:root {
    --main-bg-color: #ebedf3;
    --main-blue: #52c8fa;
    --main-secondary: #FA8452;
    --main-dark-gray: #3a3a3a;
    --main-medium-gray: #7e7e7e;
    --main-light-gray: #eaeaea;
    --main-red: #fa5274;
    --snippet-background: #ffffff;
    --item-title-font: "Bungee Regular";
    --item-title-letter-spacing: .5px;
}
body {
    margin: 0;
}
@font-face{
    font-family: "Bungee Regular";
    src: url("Bungee-Regular.otf") format("opentype");
    src: url("bungee.regular.ttf") format("truetype");
}
.generic-link {
    text-decoration: none;
    color: var(--main-blue);
}
.generic-link:hover {
    text-decoration: underline;
}
.page-container {
    background-color: var(--main-bg-color);
    width: 100%;
}
.page-background {
    background-color: var(--main-bg-color);
}
.item-snippet-wrapper {
    max-width: 800px;
    margin: 0 auto;
}
.item-snippet {
    border: 1px solid var(--main-medium-gray);
    border-radius: 2px;
    padding: 10px;
    margin-bottom: 4px;
    margin-left: 4px;
    margin-right:3px;
    box-shadow: 1px 1px var(--main-blue);
    background-color: var(--snippet-background);
}
.item-snippet p {
    margin: 0 0;
}
.item-title {
    font-family: var(--item-title-font);
    line-height: 1.0;
    text-transform: uppercase;
    letter-spacing: var(--item-title-letter-spacing);
    word-spacing: var(--item-title-letter-spacing);
    vertical-align: text-bottom;
}
.item-title a {
    text-decoration: none;
    color: var(--main-dark-gray);
}
.item-date {
    color: var(--main-medium-gray);
}
.item-content-wrapper {
    overflow: visible;
    padding: 10px 10px;
}
.item-teaser {
    display: inline-block;
    padding-bottom: 5px;
}
.item-highlight {
    font-family: var(--item-title-font);
    text-transform: uppercase;
    color: var(--main-blue);
    display: inline-block;
    line-height: 100%;
    letter-spacing: var(--item-title-letter-spacing);
    word-spacing: var(--item-title-letter-spacing);
}
.item-secondary {
    color: var(--main-medium-gray) !important;
}
.center-text {
    text-align: center;
}
.power-ranking-div {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
.power-ranking-div > span {
    padding: 4px 20px;
    font-size: 20px;
    transform: skew(-20deg);
}
.power-ranking-s {
    background: linear-gradient(135deg, var(--main-blue) 5%, #ffffff 5%, #ffffff 15%, var(--main-blue) 15%);
    border: 2px solid var(--main-light-gray);
}
.power-ranking-a {
    background: linear-gradient(135deg, #52fa84 5%, #ffffff 5%, #ffffff 15%, #52fa84 15%);
    border: 2px solid var(--main-light-gray);
}
.power-ranking-f {
    background: linear-gradient(135deg, var(--main-red) 5%, #ffffff 5%, #ffffff 15%, var(--main-red) 15%);
    border: 2px solid var(--main-light-gray);
}
.power-ranking-score {
    font-weight: 900;
}