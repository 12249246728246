.menu-bar-container {
    background-color: #ffffff;
    display: flex;
    justify-content: space-between;
    
}
.menu-bar-item-div {
    width: 100%;
    text-align: right;
    line-height: 100%;
    margin-right: 10px;
}
.menu-bar-span {
    text-align: center;
    background-color: #eaeaea;
    padding-left: 6px;
    padding-right: 6px;
    padding-bottom: 2px;
    margin: 2px;
    border-radius: 4px;
    display: inline-block;
}
.menu-bar-span a {
    font-family: "Bungee Regular";
    font-size: small;
    text-decoration: none;
    color: #7e7e7e;
    line-height: 100%;
}
.menu-bar-active-item {
    color: #52c8fa !important;
}