.loading-container {
    max-width:800px;
    margin: 0 auto;
    padding: 4px;
}
.loading-wrapper {
    border: 1px solid #7e7e7e;
    border-radius: 2px;
    box-shadow: 1px 1px #52c8fa;
    background-color: #ffffff;
    font-family: "Bungee Regular";
    display: inline-block;
    color: #3a3a3a;
    width: 100%;
}
.loading-card {
    text-align:center;
}
.loading-card p{
}
.loading-animated-span {
    color: #52c8fa;
}