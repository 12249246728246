.menu-bar-container {
    background-color: #ffffff;
    display: flex;
    justify-content: space-between;
    
}
.menu-bar-item-div {
    width: 100%;
    text-align: right;
    line-height: 100%;
    margin-right: 10px;
}
.menu-bar-span {
    text-align: center;
    background-color: #eaeaea;
    padding-left: 6px;
    padding-right: 6px;
    padding-bottom: 2px;
    margin: 2px;
    border-radius: 4px;
    display: inline-block;
}
.menu-bar-span a {
    font-family: "Bungee Regular";
    font-size: small;
    text-decoration: none;
    color: #7e7e7e;
    line-height: 100%;
}
.menu-bar-active-item {
    color: #52c8fa !important;
}
.title-bar-container {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    max-width: 100%;
    background-color: #eaeaea;
    padding:1px;
    box-shadow: 0px 1px #7e7e7e;
    z-index: 10;
}
.title-bar-image-wrapper {
    background-color: #ffffff;
    padding:4px;
    text-align: center;
}
.title-bar-image {
    max-width: 100%;
    max-height: 10vh;
}


.page-title {
    text-align: center;
    background-color: #3a3a3a;
    color: #52c8fa;
    font-size: large;
    font-family: "Bungee Regular";
    max-width: 800px;
    line-height: 150%;
    margin: 0 auto;
    padding-bottom: 0;
    border: 1px solid #ffffff;
    border-radius: 0px 0px 4px 4px;
}
.page-title {
    text-shadow: 1px 1px #3a3a3a, -1px -1px #eaeaea;
}
:root {
    --main-bg-color: #ebedf3;
    --main-blue: #52c8fa;
    --main-secondary: #FA8452;
    --main-dark-gray: #3a3a3a;
    --main-medium-gray: #7e7e7e;
    --main-light-gray: #eaeaea;
    --main-red: #fa5274;
    --snippet-background: #ffffff;
    --item-title-font: "Bungee Regular";
    --item-title-letter-spacing: .5px;
}
body {
    margin: 0;
}
@font-face{
    font-family: "Bungee Regular";
    src: url(/static/media/Bungee-Regular.13a2880f.otf) format("opentype");
    src: url(/static/media/bungee.regular.c706b489.ttf) format("truetype");
}
.generic-link {
    text-decoration: none;
    color: #52c8fa;
    color: var(--main-blue);
}
.generic-link:hover {
    text-decoration: underline;
}
.page-container {
    background-color: #ebedf3;
    background-color: var(--main-bg-color);
    width: 100%;
}
.page-background {
    background-color: #ebedf3;
    background-color: var(--main-bg-color);
}
.item-snippet-wrapper {
    max-width: 800px;
    margin: 0 auto;
}
.item-snippet {
    border: 1px solid #7e7e7e;
    border: 1px solid var(--main-medium-gray);
    border-radius: 2px;
    padding: 10px;
    margin-bottom: 4px;
    margin-left: 4px;
    margin-right:3px;
    box-shadow: 1px 1px #52c8fa;
    box-shadow: 1px 1px var(--main-blue);
    background-color: #ffffff;
    background-color: var(--snippet-background);
}
.item-snippet p {
    margin: 0 0;
}
.item-title {
    font-family: "Bungee Regular";
    font-family: var(--item-title-font);
    line-height: 1.0;
    text-transform: uppercase;
    letter-spacing: .5px;
    letter-spacing: var(--item-title-letter-spacing);
    word-spacing: .5px;
    word-spacing: var(--item-title-letter-spacing);
    vertical-align: text-bottom;
}
.item-title a {
    text-decoration: none;
    color: #3a3a3a;
    color: var(--main-dark-gray);
}
.item-date {
    color: #7e7e7e;
    color: var(--main-medium-gray);
}
.item-content-wrapper {
    overflow: visible;
    padding: 10px 10px;
}
.item-teaser {
    display: inline-block;
    padding-bottom: 5px;
}
.item-highlight {
    font-family: "Bungee Regular";
    font-family: var(--item-title-font);
    text-transform: uppercase;
    color: #52c8fa;
    color: var(--main-blue);
    display: inline-block;
    line-height: 100%;
    letter-spacing: .5px;
    letter-spacing: var(--item-title-letter-spacing);
    word-spacing: .5px;
    word-spacing: var(--item-title-letter-spacing);
}
.item-secondary {
    color: #7e7e7e !important;
    color: var(--main-medium-gray) !important;
}
.center-text {
    text-align: center;
}
.power-ranking-div {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
.power-ranking-div > span {
    padding: 4px 20px;
    font-size: 20px;
    -webkit-transform: skew(-20deg);
            transform: skew(-20deg);
}
.power-ranking-s {
    background: linear-gradient(135deg, #52c8fa 5%, #ffffff 5%, #ffffff 15%, #52c8fa 15%);
    background: linear-gradient(135deg, var(--main-blue) 5%, #ffffff 5%, #ffffff 15%, var(--main-blue) 15%);
    border: 2px solid #eaeaea;
    border: 2px solid var(--main-light-gray);
}
.power-ranking-a {
    background: linear-gradient(135deg, #52fa84 5%, #ffffff 5%, #ffffff 15%, #52fa84 15%);
    border: 2px solid #eaeaea;
    border: 2px solid var(--main-light-gray);
}
.power-ranking-f {
    background: linear-gradient(135deg, #fa5274 5%, #ffffff 5%, #ffffff 15%, #fa5274 15%);
    background: linear-gradient(135deg, var(--main-red) 5%, #ffffff 5%, #ffffff 15%, var(--main-red) 15%);
    border: 2px solid #eaeaea;
    border: 2px solid var(--main-light-gray);
}
.power-ranking-score {
    font-weight: 900;
}
.article-source {
    color: var(--main-medium-gray);
}
.article-date {
    font-family: var(--item-title-font);
    color: var(--main-blue);
    display: inline-block;
    line-height: 100%;
}
.article-headline {
    color: var(--main-blue);
    font-size: 24px;
    margin: 0 auto;
}
.article-teaser {
    display: inline-block;
    padding-bottom: 5px;
}
.article-item-div {
    padding-top: 8px;
    padding-bottom: 8px;
}
.article-paragraph {
    display: inline-block;
    padding-bottom: 5px;
}
.article-image {
    display: block;
    max-height: 25vh;
    margin: auto;
    padding: 5px;
    border: 1px solid var(--main-medium-gray);
    border-radius: 2px;
    max-width: 80%;
}
.article-image-main {
    display: block;
    margin: auto;
    padding: 5px;
    border: 1px solid var(--main-medium-gray);
    border-radius: 2px;
    max-width: 95%;
}
.disclaimer-wrapper {
    padding: 10px;
    max-width: 800px;
    margin: 0 auto;
    text-align: justify;
}
.disclaimer-content {
    color: #7e7e7e;
    font-size: small;
}
.disclaimer-divider-line {
    width: 80%;
	border-top: 1px dotted #7e7e7e;
	border-bottom: 1px dotted #ffffff;
}
.creative-ape-notice {
    color: #7e7e7e;
    font-size: small;
    text-align: center;
}
.creative-ape-notice a {
    text-decoration: none;
    color: #3a3a3a;
}
.loading-container {
    max-width:800px;
    margin: 0 auto;
    padding: 4px;
}
.loading-wrapper {
    border: 1px solid #7e7e7e;
    border-radius: 2px;
    box-shadow: 1px 1px #52c8fa;
    background-color: #ffffff;
    font-family: "Bungee Regular";
    display: inline-block;
    color: #3a3a3a;
    width: 100%;
}
.loading-card {
    text-align:center;
}
.loading-card p{
}
.loading-animated-span {
    color: #52c8fa;
}
