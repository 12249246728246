.title-bar-container {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    max-width: 100%;
    background-color: #eaeaea;
    padding:1px;
    box-shadow: 0px 1px #7e7e7e;
    z-index: 10;
}
.title-bar-image-wrapper {
    background-color: #ffffff;
    padding:4px;
    text-align: center;
}
.title-bar-image {
    max-width: 100%;
    max-height: 10vh;
}
